import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Location } from '@reach/router'
import logo from '../images/logos/intercept2.svg'

const Header = (props) => (

  <Location>
  {({location}) => (
    <>
    {/* <header className={location.pathname === '/' ? 'site--header home' : 'site--header'}> */}
    <header className="subpage--header">
      <div className="site--wrapper">
        <nav className="site--nav">
          <ul>
          <li><Link to="/"><img src={logo} alt="intercept" /></Link></li>
            <li><a href="/why/">Why</a></li>
            <li><a href="/how/">How</a></li>
            <li><a href="/who/">Who</a></li>
            <li><a href="/news/">News</a></li>
            <li><a href="/contact/">Contact</a></li>
            <li><a href="/faq/">FAQ</a></li>
          </ul>
        </nav>
      </div>
    </header>
    </>
  )}
  </Location>

  
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
