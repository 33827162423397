/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import SubpageHeader from "./subpage-header"
import Footer from "./footer"
import "./css/style.css"

const SubpageLayout = ({ children, question, fact, location }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleSubpageQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={data => (
      <>
        <SubpageHeader path={location.pathname} />
        
        <article className="subpage--content-wrapper">{children}</article>
        
        <Footer question={question} fact={fact}/>
      </>
    )}
  />
)

SubpageLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

SubpageLayout.defaultProps = {
  location: {},
}

export default SubpageLayout
