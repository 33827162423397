import React from 'react';

import SubpageLayout from "../components/subpage-layout"
import Seo from "../components/seo"

const Contact = () => (
   <SubpageLayout>
      <Seo title="Contact" />
      <header className="subpage--content-header">
        <h1>Thank You</h1>
        <h2>
          Thanks for your submission. We will notify you when Intercept is ready
          for primetime.
        </h2>
      </header>

      <section></section>
   </SubpageLayout>

)

export default Contact